.containerTable{
    border-radius: 20px;
}

.headerTable{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    margin: 1rem;
    margin-bottom: -1rem;
    // background-color: ;
}

.TitleTable{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 600;
    color: #315694;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.SubtitleTable{
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.reloadTable{
    color: rgb(0, 168, 0);
}

.containerTitleColumn{
    background-color: rgba(37, 101, 149, 0.393); //aqui
    text-align: center;
    font-weight: bold;
    color: white; //aqui
    height: 50px;
}

#card-menu{
    background-color: white;
    width: 180px;
}

#dropdown-basic{
    width: 115px;
    height: 45px;
    background: #F26C4F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

#dropdown-basic:hover {
    background: #F26C4F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

#dropdown-basic:active {
    background: #F26C4F;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.TitleColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CellContent{
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    border: 2px solid #f5f5f5;
    margin-bottom: 0;
    margin-top: 0;
    max-height: fit-content;
    text-align: center;
    width: 50px;
    align-items: center;
}

.contenido{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.CellActions{
    width: 50px;
}

.InputSearch{
    background-color: white;
}

.SortButton{
    border: none;
    font-size: small;
    background-color: transparent;
}

.SettingButton{
    border: none;
    font-size: small;
    background-color: transparent;
    color: #1976d2;
    border-radius: 5px;
}

.SettingButton:hover{
    background-color: #1976d2;
    color: white;
}

.SortButton{
    color: white;
}

// .SortButton:hover{
//     color: #1976d2;
// }

.MinCell:nth-child(1){
    width: 30px;
} 

.menu-opciones{
    list-style: none;
}

.action-item-color{
    background-color: #7c9fc469;

}

.generalButtonColor{
    color: #4f4f4fcf;
}

.Button-action{
    padding: 5px;
    margin-bottom: 2px;
}

.redButton:hover{
    cursor: pointer;
    background-color: rgba(242, 108, 79, 0.25);
}

.redButton:hover > i{
    color: #F26C4F;
}

.blueButton:hover {
    cursor: pointer;
    background-color: rgba(58, 137, 201, 0.25);
}

.blueButton:hover > i{
    color: #3A89C9;
}

.perryButton:hover {
    cursor: pointer;
    background-color: rgba(22, 147, 165, 0.25);
}

.perryButton:hover > i{
    color: #1693A5;
}

.reyButton:hover {
    cursor: pointer;
    background-color: rgba(27, 50, 95, 0.4);
}

.reyButton:hover > i{
    color: #1B325F;
}

.greenButton:hover {
    cursor: pointer;
    background-color: rgba(216, 216, 192, 0.5);
}

.greenButton:hover > i{
    color: black;
}

.yellowButton:hover {
    cursor: pointer;
    background-color: rgba(255, 213, 73, 0.5);
}

.yellowButton:hover > i{
    color: black;
}

//TABLA PAGINADO

.tabla_paginado{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.tabla_paginado_num{
    // border: solid 2px #3A89C9;
    border: none;
    background-color: rgba(36, 145, 161, 0.5);
    margin: 0px 3px;
    border-radius: 2px;
    color: white;
    font-weight: bold;
}

.tabla_paginado_num:disabled{
    background-color: #3A89C9;
}

.tabla_paginado_num:active{
    transform: translateY(4px);
}

.tabla_paginado_num:disabled:active{
    transform: translateY(0px);
}

.tabla_paginado_flecha{
    background-color: white;
    color: #3A89C9;
    font-weight: bold;
    border: none;
}

.tabla_paginado_flecha:disabled{
    // color: #D8D8C0;
    color: rgb(201, 195, 195);
}

.tabla_paginado_flecha:active{
    transform: translateY(4px);
}

.tabla_paginado_flecha:disabled:active{
    transform: translateY(0px);
}

.holis{
    background-color: red;
}

.descripcion {
    max-width: 120px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    // white-space: pre;
    text-align: center;
    text-overflow: ellipsis;
    // position: relative;
  }

  .ID {
    max-width: 1px;
    // height: 30px;
  }

  .CellActions{
    max-width: 1px;
    // height: 30px;
   }  


//   .descripcion > .contenido{
//     position: relative;
//     left: 0;
//   }

  

//   .descripcion:hover {
//    height: auto;
//    white-space: unset;
//    text-overflow: unset;

//   }